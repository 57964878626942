import React, { useContext, useEffect } from 'react';
import ThemeContext, { THEMES } from 'common/context.theme';
import { graphql, useStaticQuery } from 'gatsby';

import GroupPodcast from 'components/group-podcast';
import { HEADLINE_TYPES } from 'components/headline';
import PageHero from 'components/page-hero';
import PodcastsShape from 'images/shapes/podcasts.svg';
import SpotifyPlayer from 'components/spotify-player';
import AppContext from '../common/context.app';
import SEO from '../components/seo';

const PodcastsPage = () => {
  const { setCurrentPage } = useContext(AppContext);
  const { setTheme } = useContext(ThemeContext);
  useEffect(() => {
    setTheme(THEMES.dark);
    setCurrentPage('/podcasts/');
  }, [setTheme, setCurrentPage]);

  const data = useStaticQuery(graphql`
    query {
      podcastsJson {
        categories {
          id
          header
          backgroundColor
          items {
            title
            id
            uri
          }
        }
      }
    }
  `);

  const { categories } = data.podcastsJson;

  const content = categories.map((category, cKey) => {
    const items = category.items.map((i, iKey) => (
      <SpotifyPlayer title={i.title} uri={i.uri} key={iKey} />
    ));
    return (
      <GroupPodcast
        key={cKey}
        header={category.header}
        backgroundColor={category.backgroundColor}
      >
        {items}
      </GroupPodcast>
    );
  });

  return (
    <div className="page">
      <SEO title="Podcasts" />
      <PageHero headline={HEADLINE_TYPES.PODCASTS} shape={PodcastsShape}>
        A selection of podcasts giving you an in depth look at carnival's rich
        history, music and culture
      </PageHero>
      <div className="nhc-container-right nhc-container-left nhc-container-left--mobile-only">
        {content}
      </div>
    </div>
  );
};

export default PodcastsPage;
