import PropTypes from 'prop-types';
import React from 'react';
import Styles from './group-podcast.module.css';
import { useIsTablet } from 'common/hooks/useIsTablet';

const GroupPodcast = props => {
  const isTablet = useIsTablet();
  const panelStyle = isTablet
    ? { backgroundColor: props.backgroundColor }
    : null;
  return (
    <div className={Styles.groupPodcast}>
      <div className={Styles.left}>
        <h2 className={Styles.header}>{props.header}</h2>
      </div>
      <div className={Styles.right} style={panelStyle}>
        {props.children}
      </div>
    </div>
  );
};

GroupPodcast.propTypes = {
  header: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
};

export default GroupPodcast;
